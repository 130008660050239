<template>
  <div class="pbf">
     
     <BrandTabProducts
      :inurl="'/brands-seed/' + props.id + '/strains'"
      :strain-id="props.id"
      :category="'strain'"
      :intags="tags"
      />

  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})
const tags = ref($tagsUtil.tagsBrandSeedProducts)
 
</script>

<style scoped>
 

</style>
